<template>
  <div class="md:w-5/12 lg:w-4/12 xl:w-3/12 mx-auto py-16">
    <div class="flex items-center justify-between mb-12">
      <BackButton @onClick="back"/>
      <Steps/>
    </div>
    <h2 class="font-bold text-4xl mb-20">Let us know about you as a pro...</h2>
    <ValidationObserver tag="div" v-slot="{ invalid }" rules="required" ref="proForm">
      <form @submit.prevent="createPro" class="flex flex-col space-y-8">
        <ValidationProvider name="Profile image" rules="required" v-slot="{ errors }" ref="profileImage">
          <input type="hidden" v-model="form.pro.profile_image_url">
          <div>
            <InputLabel label="Your profile image"/>
            <ImageUploadSingle
              entity="new-pro"
              :imageUrl="profileImageUrl"
              @uploaded="profileImageUpdated"
              @deleted="profileImageUpdated(null)"
              :error="errors.length > 0"
            />
          </div>
          <h4 class="text-functional-error text-xs mt-1" v-if="errors.length">{{ errors[0] }}</h4>
        </ValidationProvider>

        <ValidationProvider name="Cover image" rules="required" v-slot="{ errors }" ref="coverImage">
          <input type="hidden" v-model="form.pro.cover_image_url">
          <div>
            <InputLabel label="Your cover image"/>
            <ImageUploadSingle
              entity="new-pro"
              :imageUrl="coverImageUrl"
              @uploaded="coverImageUpdated"
              @deleted="coverImageUpdated(null)"
              cropStencilComponent="rectangle-stencil"
              :cropAspectRatio="12/6"
              :error="errors.length > 0"
            />
          </div>
          <h4 class="text-functional-error text-xs mt-1" v-if="errors.length">{{ errors[0] }}</h4>
        </ValidationProvider>

        <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
          <TextInput
            v-model="form.user.title"
            label="What is your title?"
            x-padding="px-5"
            placeholder="Graphic Designer"
            :errors="errors"
          />
        </ValidationProvider>

        <BaseMultiselect :options="$constants.PROS.COUNTRIES" trackBy="code" label="Country" :multiple="false" type="country" @selected="setUserCountry" />

        <ValidationProvider name="State" rules="required" v-slot="{ errors }">
          <TextInput
            v-model="form.user.state"
            label="State/province"
            x-padding="px-5"
            placeholder="Florida"
            :errors="errors"
          />
        </ValidationProvider>

        <ValidationProvider name="City" rules="required" v-slot="{ errors }">
          <TextInput
            v-model="form.user.city"
            label="City"
            x-padding="px-5"
            placeholder="Miami"
            :errors="errors"
          />
        </ValidationProvider>

        <TextInput
          v-model="userPhone"
          label="Phone"
          x-padding="px-5"
          :phoneInput="true"
          @phone="setPhone($event)"
        />

        <BaseMultiselect :options="categories" label="Categories (select one or more)"  type="category" @selected="setProCategories" />

        <TagMultiselect :options="skills" label="Skills" type="skill" @selected="setProSkills" :optionsLimit="8"/>

        <ValidationProvider name="Bio" vid="pro.bio" rules="required" v-slot="{ errors }">
          <TextAreaInput v-model="form.pro.bio" label="Your bio" :errors="errors"/>
        </ValidationProvider>

        <ValidationProvider name="Education" vid="pro.education" rules="required" v-slot="{ errors }">
          <TextAreaInput v-model="form.pro.education" label="Your education and experience" :errors="errors"/>
        </ValidationProvider>

        <ValidationProvider name="Portfolio" rules="required" v-slot="{ errors }">
          <div>
            <InputLabel label="Add portfolio images"/>
            <ImageUploadMultiple
              :images="portfolio"
              @uploaded="portfolioUpdated"
            />
          </div>
          <p class="text-functional-error text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
        </ValidationProvider>

        <div class="col-span-2 md:col-span-1">
          <button type="submit" :disabled="invalid">
            <ButtonIcon text="Create account" :disabled="invalid" :loading="loading"/>
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {mapMutations, mapState, mapActions} from "vuex";
import BackButton from "@/components/shared/buttons/BackButton";
import Steps from "@/components/register/Steps";
import TextInput from "@/components/shared/inputs/TextInput";
import TextAreaInput from "@/components/shared/inputs/TextAreaInput";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import BaseMultiselect from "@/components/shared/inputs/BaseMultiselect";
import TagMultiselect from "@/components/shared/inputs/TagMultiselect";
import ImageUploadSingle from '@/components/shared/ImageUploadSingle';
import ImageUploadMultiple from '@/components/shared/ImageUploadMultiple';
import InputLabel from "@/components/shared/labels/InputLabel";

export default {
  name: "ProForm",
  components: {
    ButtonIcon,
    TextAreaInput,
    TextInput,
    Steps,
    BackButton,
    BaseMultiselect,
    TagMultiselect,
    ImageUploadSingle,
    ImageUploadMultiple,
    InputLabel,
  },

  data() {
    return {
      profileImageUrl: null,
      coverImageUrl: null,
      portfolio: [],
      loading: false,
      userPhone: null,
    }
  },

  beforeMount() {
    this.initProForm()
  },

  mounted() {
    this.fetchCategories();
    this.fetchSkills();
    if (this.type != 'pro') {
      this.back();
    }
  },

  methods: {
    ...mapActions({
      register: 'auth/register',
      fetchCategories: 'categories/fetch',
      fetchSkills: 'skills/fetch',
    }),

    ...mapMutations({
      decreaseStep: 'auth/decreaseStep',
      setUserCountry: 'auth/setUserCountry',
      setProCategories: 'auth/setProCategories',
      setProSkills: 'auth/setProSkills',
      initProForm: 'auth/initProForm',
      setProProfileImage: 'auth/setProProfileImage',
      setProCoverImage: 'auth/setProCoverImage',
      setProPortfolio: 'auth/setProPortfolio',
    }),

    back() {
      this.$router.back();
      this.decreaseStep();
    },

    async createPro(){
      await this.$refs.profileImage.validate();
      await this.$refs.coverImage.validate();
      this.loading = true;
      this.register(this.form)
        .then(response => {
          if (response) {
            window.fbq('trackCustom', 'signup-pro');
            this.$router.push({name:'Home'});
          }
        })
        .catch(errors => {
          if (errors.response.status == 422) {
            this.$refs.proForm.setErrors(errors.response.data.errors)
          }
        })
        .finally(() => this.loading = false)
    },

    profileImageUpdated(url) {
      this.profileImageUrl = url;
      this.setProProfileImage(url)
      this.$nextTick(() => {
        this.$refs.profileImage.validate();
      });
    },

    coverImageUpdated(url) {
      this.coverImageUrl = url;
      this.setProCoverImage(url);
      this.$nextTick(() => {
        this.$refs.coverImage.validate();
      });
    },

    portfolioUpdated(url) {
      const index = this.portfolio.findIndex(p => p == url);
      if (index >= 0) {
        this.portfolio.splice(index, 1)
      } else {
        this.portfolio.push(url)
      }
      this.setProPortfolio(this.portfolio)
    },

    setPhone(phoneObject) {
      this.form.user.phone_country_code = phoneObject.countryCode;
      this.form.user.phone = phoneObject.number;
    },
  },

  computed: {
    ...mapState({
      form: state => state.auth.form,
      type: state => state.auth.type,
      categories: state => state.categories.items,
      skills: state => state.skills.items,
    }),
  },
}
</script>

