var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md:w-5/12 lg:w-4/12 xl:w-3/12 mx-auto pt-16"},[_c('div',{staticClass:"flex items-center justify-between mb-12"},[_c('BackButton',{on:{"onClick":_vm.back}}),_c('Steps')],1),_c('h2',{staticClass:"font-bold text-4xl mb-20"},[_vm._v("Let’s get to know some stuff about you...")]),_c('ValidationObserver',{ref:"userForm",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"grid grid-cols-2 gap-x-4 gap-y-8",on:{"submit":function($event){$event.preventDefault();return _vm.next($event)}}},[_c('ValidationProvider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"First Name","x-padding":"px-5","placeholder":"Name","errors":errors},model:{value:(_vm.form.user.first_name),callback:function ($$v) {_vm.$set(_vm.form.user, "first_name", $$v)},expression:"form.user.first_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Last Name","x-padding":"px-5","placeholder":"Last name","errors":errors},model:{value:(_vm.form.user.last_name),callback:function ($$v) {_vm.$set(_vm.form.user, "last_name", $$v)},expression:"form.user.last_name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"disabled":_vm.form.isOauthRegistration,"label":"Email","x-padding":"px-5","placeholder":"Email","errors":errors},on:{"onBlur":function($event){return _vm.emailCheck(errors)}},model:{value:(_vm.form.user.email),callback:function ($$v) {_vm.$set(_vm.form.user, "email", $$v)},expression:"form.user.email"}},[(_vm.loading)?_c('template',{slot:"rightIcon"},[_c('loading-icon',{staticClass:"h-2 my-1"})],1):(_vm.check != null)?_c('template',{slot:"rightIcon"},[(!_vm.check || errors.length)?_c('XIcon',{staticClass:"text-red-600",attrs:{"strokeWidth":"2"}}):_c('CheckIcon')],1):_vm._e()],2)]}}],null,true)}),(!_vm.form.isOauthRegistration)?_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"name":"Password","rules":"required|min:8","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"type":"password","label":"Password","x-padding":"px-5","errors":errors},model:{value:(_vm.form.user.password),callback:function ($$v) {_vm.$set(_vm.form.user, "password", $$v)},expression:"form.user.password"}})]}}],null,true)}):_vm._e(),(!_vm.form.isOauthRegistration)?_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"name":"Password confirmation","rules":"required|min:8|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"type":"password","label":"Confirm password","x-padding":"px-5","errors":errors},model:{value:(_vm.form.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form.user, "password_confirmation", $$v)},expression:"form.user.password_confirmation"}})]}}],null,true)}):_vm._e(),_c('div',{staticClass:"col-span-2 md:col-span-1"},[_c('button',{attrs:{"type":"submit","disabled":invalid}},[_c('ButtonIcon',{attrs:{"text":"Sign Up","disabled":invalid}})],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }