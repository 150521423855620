var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md:w-5/12 lg:w-4/12 xl:w-3/12 mx-auto py-16"},[_c('div',{staticClass:"flex items-center justify-between mb-12"},[_c('BackButton',{on:{"onClick":_vm.back}}),_c('Steps')],1),_c('h2',{staticClass:"font-bold text-4xl mb-20"},[_vm._v("Let us know about you as a pro...")]),_c('ValidationObserver',{ref:"proForm",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"flex flex-col space-y-8",on:{"submit":function($event){$event.preventDefault();return _vm.createPro($event)}}},[_c('ValidationProvider',{ref:"profileImage",attrs:{"name":"Profile image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.pro.profile_image_url),expression:"form.pro.profile_image_url"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.form.pro.profile_image_url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.pro, "profile_image_url", $event.target.value)}}}),_c('div',[_c('InputLabel',{attrs:{"label":"Your profile image"}}),_c('ImageUploadSingle',{attrs:{"entity":"new-pro","imageUrl":_vm.profileImageUrl,"error":errors.length > 0},on:{"uploaded":_vm.profileImageUpdated,"deleted":function($event){return _vm.profileImageUpdated(null)}}})],1),(errors.length)?_c('h4',{staticClass:"text-functional-error text-xs mt-1"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{ref:"coverImage",attrs:{"name":"Cover image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.pro.cover_image_url),expression:"form.pro.cover_image_url"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.form.pro.cover_image_url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.pro, "cover_image_url", $event.target.value)}}}),_c('div',[_c('InputLabel',{attrs:{"label":"Your cover image"}}),_c('ImageUploadSingle',{attrs:{"entity":"new-pro","imageUrl":_vm.coverImageUrl,"cropStencilComponent":"rectangle-stencil","cropAspectRatio":12/6,"error":errors.length > 0},on:{"uploaded":_vm.coverImageUpdated,"deleted":function($event){return _vm.coverImageUpdated(null)}}})],1),(errors.length)?_c('h4',{staticClass:"text-functional-error text-xs mt-1"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"What is your title?","x-padding":"px-5","placeholder":"Graphic Designer","errors":errors},model:{value:(_vm.form.user.title),callback:function ($$v) {_vm.$set(_vm.form.user, "title", $$v)},expression:"form.user.title"}})]}}],null,true)}),_c('BaseMultiselect',{attrs:{"options":_vm.$constants.PROS.COUNTRIES,"trackBy":"code","label":"Country","multiple":false,"type":"country"},on:{"selected":_vm.setUserCountry}}),_c('ValidationProvider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"State/province","x-padding":"px-5","placeholder":"Florida","errors":errors},model:{value:(_vm.form.user.state),callback:function ($$v) {_vm.$set(_vm.form.user, "state", $$v)},expression:"form.user.state"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"City","x-padding":"px-5","placeholder":"Miami","errors":errors},model:{value:(_vm.form.user.city),callback:function ($$v) {_vm.$set(_vm.form.user, "city", $$v)},expression:"form.user.city"}})]}}],null,true)}),_c('TextInput',{attrs:{"label":"Phone","x-padding":"px-5","phoneInput":true},on:{"phone":function($event){return _vm.setPhone($event)}},model:{value:(_vm.userPhone),callback:function ($$v) {_vm.userPhone=$$v},expression:"userPhone"}}),_c('BaseMultiselect',{attrs:{"options":_vm.categories,"label":"Categories (select one or more)","type":"category"},on:{"selected":_vm.setProCategories}}),_c('TagMultiselect',{attrs:{"options":_vm.skills,"label":"Skills","type":"skill","optionsLimit":8},on:{"selected":_vm.setProSkills}}),_c('ValidationProvider',{attrs:{"name":"Bio","vid":"pro.bio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextAreaInput',{attrs:{"label":"Your bio","errors":errors},model:{value:(_vm.form.pro.bio),callback:function ($$v) {_vm.$set(_vm.form.pro, "bio", $$v)},expression:"form.pro.bio"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Education","vid":"pro.education","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextAreaInput',{attrs:{"label":"Your education and experience","errors":errors},model:{value:(_vm.form.pro.education),callback:function ($$v) {_vm.$set(_vm.form.pro, "education", $$v)},expression:"form.pro.education"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Portfolio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('InputLabel',{attrs:{"label":"Add portfolio images"}}),_c('ImageUploadMultiple',{attrs:{"images":_vm.portfolio},on:{"uploaded":_vm.portfolioUpdated}})],1),(errors.length)?_c('p',{staticClass:"text-functional-error text-sm mt-3 font-light"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"col-span-2 md:col-span-1"},[_c('button',{attrs:{"type":"submit","disabled":invalid}},[_c('ButtonIcon',{attrs:{"text":"Create account","disabled":invalid,"loading":_vm.loading}})],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }