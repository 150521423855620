<template>
  <div class="md:w-5/12 lg:w-4/12 xl:w-3/12 mx-auto pt-16">
    <div class="flex items-center justify-between mb-12">
      <BackButton @onClick="back"/>
      <Steps/>
    </div>
    <h2 class="font-bold text-4xl mb-20">Let’s get to know some stuff about you...</h2>
    <ValidationObserver v-slot="{ invalid }" rules="required" ref="userForm">
      <form @submit.prevent="next" class="grid grid-cols-2 gap-x-4 gap-y-8">
        <ValidationProvider name="First name" rules="required" v-slot="{ errors }">
          <TextInput v-model="form.user.first_name" label="First Name" x-padding="px-5" placeholder="Name" :errors="errors"/>
        </ValidationProvider>

        <ValidationProvider name="Last name" rules="required" v-slot="{ errors }">
          <TextInput v-model="form.user.last_name" label="Last Name" x-padding="px-5" placeholder="Last name" :errors="errors"/>
        </ValidationProvider>

        <ValidationProvider name="Email" vid="email" rules="required|email" v-slot="{ errors }" class="col-span-2">
          <TextInput :disabled="form.isOauthRegistration" v-model="form.user.email" label="Email" x-padding="px-5" placeholder="Email" :errors="errors" @onBlur="emailCheck(errors)">
            <template slot="rightIcon" v-if="loading">
              <loading-icon class="h-2 my-1"/>
            </template>
            <template slot="rightIcon" v-else-if="check != null">
              <XIcon class="text-red-600" strokeWidth="2" v-if="!check || errors.length"/>
              <CheckIcon v-else/>
            </template>
          </TextInput>
        </ValidationProvider>

        <ValidationProvider v-if="!form.isOauthRegistration" name="Password" rules="required|min:8" vid="password" v-slot="{ errors }" class="col-span-2">
          <TextInput type="password" v-model="form.user.password" label="Password" x-padding="px-5" :errors="errors"/>
        </ValidationProvider>

        <ValidationProvider v-if="!form.isOauthRegistration" name="Password confirmation" rules="required|min:8|confirmed:password" v-slot="{ errors }" class="col-span-2">
          <TextInput type="password" v-model="form.user.password_confirmation" label="Confirm password" x-padding="px-5" :errors="errors"/>
        </ValidationProvider>

        <div class="col-span-2 md:col-span-1">
          <button type="submit" :disabled="invalid">
            <ButtonIcon text="Sign Up" :disabled="invalid"/>
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {startCase} from 'lodash';
import {mapActions, mapMutations, mapState} from "vuex";
import Steps from "@/components/register/Steps";
import BackButton from "@/components/shared/buttons/BackButton";
import TextInput from "@/components/shared/inputs/TextInput";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import CheckIcon from "@/components/shared/svg/CheckIcon";
import XIcon from "@/components/shared/svg/XIcon";

export default {
  name: "UserForm",
  components: {ButtonIcon, TextInput, BackButton, Steps, CheckIcon, XIcon},

  data() {
    return {
      loading: false
    }
  },

  mounted() {
    if (!this.type) {
      this.back();
    }
  },

  methods: {
    ...mapActions({
      checkEmail: 'auth/checkEmail',
    }),
    ...mapMutations({
      decreaseStep: 'auth/decreaseStep',
      increaseStep: 'auth/increaseStep',
      setCheckEmail: 'auth/checkEmail',
    }),

    back() {
      this.$router.back();
      this.decreaseStep();
    },

    next() {
      const nextPage = `${startCase(this.type)}Form`
      this.$router.push({name: nextPage})
      this.increaseStep();
    },

    emailCheck(errors) {
      if (!errors.length) {
        this.loading = true;
        this.checkEmail({email: this.form.user.email})
          .then(response => {
            if(!response){
              this.$refs.userForm.setErrors({email: 'Email already taken'})
            }
          })
          .finally(() => this.loading = false)
      }
      if (this.form.user.email == '') {
        this.setCheckEmail(null)
      }
    }
  },

  computed: {
    ...mapState({
      form: state => state.auth.form,
      type: state => state.auth.type,
      check: state => state.auth.checkEmail,
    }),
  },
}
</script>

